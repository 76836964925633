import { toUpper } from 'lodash';
import { useGetEntityKitQuery } from '../api/core';
import { Avatar, AvatarProps } from 'primereact/avatar';
import { BaseEntityType } from '../types/common';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    entityName: string;
    avatarProps?: AvatarProps;
}

const EntityAvatar = (props: Props) => {
    const { entityType, entityID } = props;

    const { data: svg } = useGetEntityKitQuery(
        // @ts-expect-error
        { entityType, entityID },
        { skip: !entityType || !entityID }
    );

    return (
        <Avatar
            template={
                svg && (
                    <span
                        className="svg-image"
                        dangerouslySetInnerHTML={{ __html: svg }}
                    />
                )
            }
            label={toUpper(props.entityName.slice(0, 2))}
            {...props.avatarProps}
        />
    );
};

export default EntityAvatar;
