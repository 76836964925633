import { useCallback, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sortBy, uniq } from 'lodash';

import { entityMap } from '../../routes/routes';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import EntityAvatar from '../../components/EntityAvatar';
import ErrorDisplay from '../../components/ErrorDisplay';
import ListItem from '../../components/ListItem';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import AssociationForm from '../associations/AssociationForm';
import OrganisationForm from '../organisations/OrganisationForm';
import TeamForm from '../teams/TeamForm';

import { ERROR_TYPES } from '../../types/common';

import { Mixpanel } from '../../util/mixpanel';
import { SplitButton } from 'primereact/splitbutton';
import Icon from '../../components/Icon';
import { getNiceRole, getNiceEntityType } from '../../util/helper';
import { useLazyGetRolesQuery } from '../../api/core';

const EntityListingView = (props) => {
    const {
        data,
        isError,
        isFetching,
        isLoading,
        filter,
        search,
        setFilter,
        setSearch,
        onLoadMore,
        showPagination,
        user,
    } = props;

    const formResponseToast = useRef();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [getRoles] = useLazyGetRolesQuery();

    const createEntityType = searchParams.get('create');

    const openCreationPortal = useCallback(
        (entityType) => {
            if (!searchParams.get('create')) {
                setSearchParams((params) => {
                    params.set('create', entityType);
                    return params;
                });

                Mixpanel.track(`Open ${entityType} Create`, {
                    entityType,
                });
            }
        },
        [searchParams, setSearchParams]
    );

    const closeCreationPortal = () => {
        if (searchParams.get('create')) {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
    };

    const entityTypes = useMemo(
        () => uniq(data.map((ent) => ent.entityType)),
        [data]
    );

    const splitButtonOptions = useMemo(() => {
        let options = [
            {
                key: 'team-join',
                label: `Join Team`,
                command: () => navigate(`/u/${user.userID}/team-join`),
            },
        ];

        if (user.admin) {
            options = [
                ...options,
                {
                    key: 'organistion',
                    label: `Create Organisation`,
                    command: () => openCreationPortal('organisations'),
                },
                {
                    key: 'association',
                    label: `Create Association`,
                    command: () => openCreationPortal('associations'),
                },
            ];
        }

        return options;
    }, [user, openCreationPortal, navigate]);

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc={`We were unable to load your entities, try again or contact your account manager.`}
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    // empty array to populate rows for skeleton loading components
    const blankRows = Array(5);

    const columns = [
        {
            field: 'entityPreview.entityName',
            header: 'Name',
            sortable: true,
            body: isLoading ? (
                <Skeleton />
            ) : (
                (data) => (
                    <ListItem
                        disablePadding
                        start={
                            <EntityAvatar
                                entityID={data.entityID}
                                entityName={data.entityPreview.entityName}
                                entityType={data.entityType}
                            />
                        }
                        title={data.entityPreview.entityName}
                        caption={getNiceEntityType(data.entityType)}
                    />
                )
            ),
        },
        {
            field: 'role',
            header: 'Role',
            sortable: true,
            body: (data) =>
                data.roles.map((role) => getNiceRole(role.roleName)),
        },
    ];

    // Handle successful create
    const handleCreateSuccess = (response, entityType) => {
        // Show toast
        formResponseToast.current?.show({
            severity: 'success',
            summary: 'Success!',
            detail: `The new ${entityType} has been successfully created.`,
        });

        // Close create sidebar
        closeCreationPortal();

        // Refresh roles after two seconds.
        setTimeout(() => {
            getRoles({ userID: user.userID });
        }, 2000);
    };

    const handleCreateError = (entityType) => {
        formResponseToast.current?.show({
            severity: 'error',
            summary: 'Error',
            detail: `There was an error creating your new ${entityType}. Please try again.`,
        });
    };

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : [
                          {
                              command: () => openCreationPortal('teams'),
                              icon: 'add',
                              key: 'team',
                              label: `Create Team`,
                          },
                      ]
            }
            alignment="middle"
            desc={isError ? 'Refresh to try the request again' : ``}
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No results found`}
        />
    );

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    return (
        <>
            <PageContainer size="narrow">
                <div className="entity-widget">
                    <PageHeader
                        showBreadcrumbs={false}
                        title={`Welcome, ${user.firstName}`}
                        actions={
                            <SplitButton
                                icon="add"
                                label="Create Team"
                                onClick={() => openCreationPortal('teams')}
                                dropdownIcon={<Icon name="arrow_drop_down" />}
                                model={splitButtonOptions}
                            />
                        }
                    />
                    <DataTable
                        globalFilter={search}
                        header={
                            <Toolbar
                                start={
                                    <SelectButton
                                        value={filter}
                                        onChange={(e) => {
                                            setFilter(!e.value ? [] : e.value);
                                        }}
                                        options={entityTypes.map((ent) => ({
                                            label: ent,
                                            value: ent,
                                        }))}
                                        multiple={false}
                                    />
                                }
                                end={
                                    data.length > 0 && (
                                        <InputText
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                            placeholder="Search"
                                        />
                                    )
                                }
                            />
                        }
                        selectionMode="single"
                        onRowClick={(row) => {
                            const urlSegment =
                                entityMap[row.data.entityType].urlSegment;
                            navigate(`/${urlSegment}/${row.data.entityID}`);
                        }}
                        emptyMessage={tableEmptyMessage}
                        footer={
                            !isFetching && showPagination
                                ? footerTemplate
                                : null
                        }
                        loading={isFetching && !isLoading}
                        value={
                            isLoading
                                ? blankRows
                                : sortBy(
                                      data.filter(
                                          (ent) =>
                                              filter.length <= 0 ||
                                              filter.includes(ent.entityType)
                                      ),
                                      (ent) => ent.entityPreview.entityName
                                  )
                        }
                    >
                        {data.length > 0 &&
                            columns.map((col, i) => (
                                <Column key={`col-${i}`} {...col} />
                            ))}
                    </DataTable>
                </div>
            </PageContainer>
            <Sidebar
                header={`Create ${
                    createEntityType ? createEntityType.slice(0, -1) : ''
                }`}
                onHide={closeCreationPortal}
                visible={searchParams.has('create')}
                position="right"
                style={{ width: '500px' }}
            >
                {createEntityType === 'associations' ? (
                    <AssociationForm
                        onError={() => handleCreateError('association')}
                        onSuccess={(response) =>
                            handleCreateSuccess(response, 'association')
                        }
                        onCancel={closeCreationPortal}
                    />
                ) : createEntityType === 'organisations' ? (
                    <OrganisationForm
                        onError={() => handleCreateError('organisation')}
                        onSuccess={(response) =>
                            handleCreateSuccess(response, 'organisation')
                        }
                        onCancel={closeCreationPortal}
                    />
                ) : (
                    <TeamForm
                        userID={user.userID}
                        onError={() => handleCreateError('team')}
                        onSuccess={(response) =>
                            handleCreateSuccess(response, 'team')
                        }
                        onCancel={closeCreationPortal}
                    />
                )}
            </Sidebar>
            <Toast ref={formResponseToast} />
        </>
    );
};

export default EntityListingView;
